import {NextRequest} from 'next/server'

export function mobileOrDesktopFromUserAgent(userAgent = ''): 'mobile' | 'desktop' {
  const safeUserAgent = typeof userAgent === 'string' ? userAgent : ''
  const isMobile = !!safeUserAgent.match(/Mobi/i)

  return isMobile ? 'mobile' : 'desktop'
}

export default function mobileOrDesktopFromRequest(req: NextRequest): 'mobile' | 'desktop' {
  // const ua = userAgent(req)
  // const {device} = ua
  // if (!device?.type)
  // return mobileOrDesktopFromUserAgent(ua.ua)

  // const viewport = device.type === 'mobile' ? 'mobile' : 'desktop'
  // return viewport

  return mobileOrDesktopFromUserAgent(req.headers.get('user-agent'))
}
