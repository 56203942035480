import type {AppContext, AppProps, NextWebVitalsMetric} from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Router from 'next/router'
import {appWithTranslation} from 'next-i18next'
import NProgress from 'nprogress' //nprogress module

const Root = dynamic(() => import('@providers/Root'))

import {mobileOrDesktopFromUserAgent} from '@helpers/misc/mobileOrDesktopFromUserAgent'
import App from 'next/app'

import 'reflect-metadata'

// NOTE: These can't be extracted into a separate JS file since Next needs all global css files to be loaded directly in _app
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-multi-carousel/lib/styles.css'
import 'rc-slider/assets/index.css'
import '@styles/layout/tailwind.css' // This goes on top to allow overriding
import '@styles/globals.css'
import '@styles/loading.css'
import '@packages/justo-parts/lib/styles.css'
import '@styles/layout/paginated-base.css'
import '@styles/layout/main.css'
import '@styles/layout/flexbox.css'
import '@styles/layout/helpers.css'
import '@styles/layout/title.css'
import '@styles/layout/content.css'
import '@styles/layout/buttons.css'
import '@styles/layout/animations.css'
import '@styles/layout/label.css'
import '@styles/layout/paginated.css'
import '@styles/layout/texts.css'
import '@styles/layout/tabs.css'
import '@styles/layout/input.css'
import '@styles/layout/colors.css'
import '@styles/layout/slider.css'
import '@styles/layout/small-form.css'
import '@styles/layout/animate.css'
import '@styles/layout/loading.css'
import '@styles/layout/new-styles.css'
import 'nprogress/nprogress.css'
import 'react-credit-cards/es/styles-compiled.css'

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())
NProgress.configure({showSpinner: false})

function MyApp({Component, pageProps}: AppProps) {
  return (
    <div>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
      </Head>
      <Root mobileOrDesktop={pageProps.mobileOrDesktop} websiteId={pageProps.websiteId}>
        <Component {...pageProps} />
      </Root>
    </div>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const req = appContext.ctx.req
  const mobileOrDesktop = req ? mobileOrDesktopFromUserAgent(req.headers['user-agent']) : 'desktop'

  return {
    pageProps: {
      ...appProps.pageProps,
      mobileOrDesktop
    }
  }
}
export default appWithTranslation(MyApp)

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.debug(metric)
}
